import Resource from "./resource.js";
export class Realms extends Resource {
    /**
     * Realm
     * https://www.keycloak.org/docs-api/11.0/rest-api/#_realms_admin_resource
     */
    find = this.makeRequest({
        method: "GET",
    });
    create = this.makeRequest({
        method: "POST",
        returnResourceIdInLocationHeader: { field: "realmName" },
    });
    findOne = this.makeRequest({
        method: "GET",
        path: "/{realm}",
        urlParamKeys: ["realm"],
        catchNotFound: true,
    });
    update = this.makeUpdateRequest({
        method: "PUT",
        path: "/{realm}",
        urlParamKeys: ["realm"],
    });
    del = this.makeRequest({
        method: "DELETE",
        path: "/{realm}",
        urlParamKeys: ["realm"],
    });
    partialImport = this.makeRequest({
        method: "POST",
        path: "/{realm}/partialImport",
        urlParamKeys: ["realm"],
        payloadKey: "rep",
    });
    export = this.makeRequest({
        method: "POST",
        path: "/{realm}/partial-export",
        urlParamKeys: ["realm"],
        queryParamKeys: ["exportClients", "exportGroupsAndRoles"],
    });
    getDefaultGroups = this.makeRequest({
        method: "GET",
        path: "/{realm}/default-groups",
        urlParamKeys: ["realm"],
    });
    addDefaultGroup = this.makeRequest({
        method: "PUT",
        path: "/{realm}/default-groups/{id}",
        urlParamKeys: ["realm", "id"],
    });
    removeDefaultGroup = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/default-groups/{id}",
        urlParamKeys: ["realm", "id"],
    });
    getGroupByPath = this.makeRequest({
        method: "GET",
        path: "/{realm}/group-by-path/{path}",
        urlParamKeys: ["realm", "path"],
    });
    /**
     * Get events Returns all events, or filters them based on URL query parameters listed here
     */
    findEvents = this.makeRequest({
        method: "GET",
        path: "/{realm}/events",
        urlParamKeys: ["realm"],
        queryParamKeys: [
            "client",
            "dateFrom",
            "dateTo",
            "first",
            "ipAddress",
            "max",
            "type",
            "user",
            "username",
        ],
    });
    getConfigEvents = this.makeRequest({
        method: "GET",
        path: "/{realm}/events/config",
        urlParamKeys: ["realm"],
    });
    updateConfigEvents = this.makeUpdateRequest({
        method: "PUT",
        path: "/{realm}/events/config",
        urlParamKeys: ["realm"],
    });
    clearEvents = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/events",
        urlParamKeys: ["realm"],
    });
    clearAdminEvents = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/admin-events",
        urlParamKeys: ["realm"],
    });
    getClientRegistrationPolicyProviders = this.makeRequest({
        method: "GET",
        path: "/{realm}/client-registration-policy/providers",
        urlParamKeys: ["realm"],
    });
    getClientsInitialAccess = this.makeRequest({
        method: "GET",
        path: "/{realm}/clients-initial-access",
        urlParamKeys: ["realm"],
    });
    createClientsInitialAccess = this.makeUpdateRequest({
        method: "POST",
        path: "/{realm}/clients-initial-access",
        urlParamKeys: ["realm"],
    });
    delClientsInitialAccess = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/clients-initial-access/{id}",
        urlParamKeys: ["realm", "id"],
    });
    /**
     * Remove a specific user session.
     */
    removeSession = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/sessions/{sessionId}",
        urlParamKeys: ["realm", "sessionId"],
        catchNotFound: true,
    });
    /**
     * Get admin events Returns all admin events, or filters events based on URL query parameters listed here
     */
    findAdminEvents = this.makeRequest({
        method: "GET",
        path: "/{realm}/admin-events",
        urlParamKeys: ["realm"],
        queryParamKeys: [
            "authClient",
            "authIpAddress",
            "authRealm",
            "authUser",
            "dateFrom",
            "dateTo",
            "max",
            "first",
            "operationTypes",
            "resourcePath",
            "resourceTypes",
        ],
    });
    /**
     * Users management permissions
     */
    getUsersManagementPermissions = this.makeRequest({
        method: "GET",
        path: "/{realm}/users-management-permissions",
        urlParamKeys: ["realm"],
    });
    updateUsersManagementPermissions = this.makeRequest({
        method: "PUT",
        path: "/{realm}/users-management-permissions",
        urlParamKeys: ["realm"],
    });
    /**
     * Sessions
     */
    getClientSessionStats = this.makeRequest({
        method: "GET",
        path: "/{realm}/client-session-stats",
        urlParamKeys: ["realm"],
    });
    logoutAll = this.makeRequest({
        method: "POST",
        path: "/{realm}/logout-all",
        urlParamKeys: ["realm"],
    });
    deleteSession = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/sessions/{session}",
        urlParamKeys: ["realm", "session"],
        queryParamKeys: ["isOffline"],
    });
    pushRevocation = this.makeRequest({
        method: "POST",
        path: "/{realm}/push-revocation",
        urlParamKeys: ["realm"],
        ignoredKeys: ["realm"],
    });
    getKeys = this.makeRequest({
        method: "GET",
        path: "/{realm}/keys",
        urlParamKeys: ["realm"],
    });
    testLDAPConnection = this.makeUpdateRequest({
        method: "POST",
        path: "/{realm}/testLDAPConnection",
        urlParamKeys: ["realm"],
    });
    testSMTPConnection = this.makeUpdateRequest({
        method: "POST",
        path: "/{realm}/testSMTPConnection",
        urlParamKeys: ["realm"],
    });
    ldapServerCapabilities = this.makeUpdateRequest({
        method: "POST",
        path: "/{realm}/ldap-server-capabilities",
        urlParamKeys: ["realm"],
    });
    getRealmSpecificLocales = this.makeRequest({
        method: "GET",
        path: "/{realm}/localization",
        urlParamKeys: ["realm"],
    });
    getRealmLocalizationTexts = this.makeRequest({
        method: "GET",
        path: "/{realm}/localization/{selectedLocale}",
        urlParamKeys: ["realm", "selectedLocale"],
    });
    addLocalization = this.makeUpdateRequest({
        method: "PUT",
        path: "/{realm}/localization/{selectedLocale}/{key}",
        urlParamKeys: ["realm", "selectedLocale", "key"],
        headers: { "content-type": "text/plain" },
    });
    deleteRealmLocalizationTexts = this.makeRequest({
        method: "DELETE",
        path: "/{realm}/localization/{selectedLocale}/{key}",
        urlParamKeys: ["realm", "selectedLocale", "key"],
    });
    // Manage MessagingQueue API
    getMessagingQueueData = this.makeRequest({
        method: "GET",
        path: "/{realm}/MessagingQueue/messagequeuegetdata",
        urlParamKeys: ["realm"],
    });
    postMessagingQueueData = this.makeRequest({
        method: "POST",
        path: "/{realm}/MessagingQueue/messagequeuesavedata",
        urlParamKeys: ["realm"],
        headers: { "content-type": "application/json" },
    });
    // Manage MessagingChannel API
    getMessagingChannelData = this.makeRequest({
        method: "GET",
        path: "/{realm}/MessagingChannel/messagebirdgetdata",
        urlParamKeys: ["realm"],
    });
    postMessagingChannelData = this.makeRequest({
        method: "POST",
        path: "/{realm}/MessagingChannel/messagebirdsavedata",
        urlParamKeys: ["realm"],
        headers: { "content-type": "application/json" },
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms",
            getBaseUrl: () => client.baseUrl,
        });
    }
}
