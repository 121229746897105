import { lazy } from "react";
import type { Path } from "react-router-dom";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import type { AppRouteObject } from "../../routes";

export type UserReportsTab = "user-access-activity-report" 
                            | "session-invalidation-report" 
                            | "brute-force-detection-report";

export type ReportsParams = {
  realm: string;
  tab?: UserReportsTab;
};

const ReportsSection = lazy(() => import("../UserAccessActivity"));

export const ReportsRoute: AppRouteObject = {
  path: "/:realm/user-reports",
  element: <ReportsSection />,
  breadcrumb: (t) => t("titleReports"),
  handle: {
    access: "view-events",
  },
};

export const ReportsWithRoute: AppRouteObject = {
  ...ReportsRoute,
  path: "/:realm/user-reports/:tab",
};

export const toReports = (params: ReportsParams): Partial<Path> => {
  const path = params.tab ? ReportsWithRoute.path : ReportsRoute.path;

  return {
    pathname: generateEncodedPath(path, params),
  };
};